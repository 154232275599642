import React, { Component } from 'react';

class Socials extends Component {
	render() {
		return (
			<div className="socials">
				{this.props.github && (
					<a href="https://www.github.com/zumod" target="_blank" rel="noopener noreferrer">
						<i class="fab fa-github" />
					</a>
				)}
				{this.props.linkedin && (
					<a href="https://www.linkedin.com/in/sumodsivadas/" target="_blank" rel="noopener noreferrer">
						<i class="fab fa-linkedin-in" />
					</a>
				)}
				{this.props.twitter && (
					<a href="https://twitter.com/sumodsivadas" target="_blank" rel="noopener noreferrer">
						<i class="fab fa-twitter" />
					</a>
				)}
				{this.props.dribbble && (
					<a href="https://dribbble.com/sumod" target="_blank" rel="noopener noreferrer">
						<i class="fab fa-dribbble" />
					</a>
				)}
				{this.props.instagram && (
					<a href="https://instagram.com/sumodzykos" target="_blank" rel="noopener noreferrer">
						<i class="fab fa-instagram" />
					</a>
				)}
				{this.props.whatsapp && (
					<a href="https://www.wa.link/ktue8q" target="_blank" rel="noopener noreferrer">
						<i class="fab fa-whatsapp" />
					</a>
				)}
				{this.props.mail && (
					<a href="mailto:contactsumod@gmail.com" target="_blank" rel="noopener noreferrer">
						<i class="far fa-envelope" />
					</a>
				)}
			</div>
		);
	}
}

export default Socials;
