import React, { Component } from 'react';
import SingleProject from '../components/SingleProject.js';
import '../SCSS/Projects.scss';

import quotes from '../assets/quotes.png';
import lightsout from '../assets/lightsout.png';
import wildfire from '../assets/wildfire.png';
import recipe from '../assets/recipe.png';
import node from '../assets/node.png';
import autocomplete from '../assets/autocomplete.png';
import dashboard from '../assets/dashboard.png';

class Projects extends Component {
	state = {};
	render() {
		return (
			<div className="Projects container">
				<div className="row">
					<h1>
						Check out my <span className="highlight">projects</span>
					</h1>
					<div className="projects-container">
						
						<SingleProject
							className="col-sm-12 col-md-6 col-lg-4 single-project"
							title="Random Quote"
							featureImage={quotes}
							tech="ReactJS, Quotable API, SASS"
							github="https://github.com/zumod/random-quotes"
							demo="https://zumod.github.io/random-quotes/"
						/>
						<SingleProject
							className="col-sm-12 col-md-6 col-lg-4 single-project"
							title="LightsOut"
							featureImage={lightsout}
							tech="ReactJS, JSX, CSS3"
							github="https://github.com/zumod/lightsout-game"
							demo="https://zumod.github.io/lightsout-game/"
						/>
						<SingleProject
							className="col-sm-12 col-md-6 col-lg-4 single-project"
							title="Wildfire Tracker"
							featureImage={wildfire}
							tech="ReactJS, Google Maps API, NASA API, CSS3"
							github="https://github.com/zumod/wildfire-tracker"
							demo="https://zumod.github.io/wildfire-tracker/"
						/>
						<SingleProject
							className="col-sm-12 col-md-6 col-lg-4 single-project"
							title="Recipe App"
							featureImage={recipe}
							tech="ReactJS, JSX, Edamam API, CSS3"
							github="https://github.com/zumod/recipe-app"
							demo="https://zumod.github.io/recipe-app/"
						/>
						<SingleProject
							className="col-sm-12 col-md-6 col-lg-4 single-project"
							title="Node REST API"
							featureImage={node}
							tech="Node, Express, CSS3"
							github="https://github.com/zumod/node-api-sample"
							demo="https://node-api-sample.herokuapp.com/"
						/>
						<SingleProject
							className="col-sm-12 col-md-6 col-lg-4 single-project"
							title="Autocomplete Searchbar"
							featureImage={autocomplete}
							tech="ReactJS, Material UI"
							github="https://github.com/zumod/search-function"
							demo="https://project1-18c0f.web.app/"
						/>
						<SingleProject
							className="col-sm-12 col-md-6 col-lg-4 single-project"
							title="Responsive Dashboard"
							featureImage={dashboard}
							tech="ReactJS, Material UI"
							github="https://github.com/zumod/dashboard"
							demo="https://dashboard-7ee59.web.app/"
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Projects;
